import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AapService } from '@services-candidat/aap.service';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import {
  Aap,
  DateTimeUtils,
  EnumFeatureFlipping,
  Utilisateur,
  EnumProjetStatut,
  EnumProjetEtape,
  ProjetListViewDto,
  Structure,
  EnumAapStatut,
} from '@shared-ui';
import { ProjetListViewService } from '@services-candidat/projet-list-view.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';

@Component({
  selector: 'pxl-projet-list',
  templateUrl: './projet-list.component.html',
  styleUrls: ['./projet-list.component.scss'],
})
export class ProjetListComponent implements OnInit, OnDestroy {
  prochaineReleves = new Map<string, string>();
  today: string;
  subscriptions: Subscription[] = [];
  aaps: Aap[];
  showQlikSenseElements = false;
  EnumFeatureFlipping = EnumFeatureFlipping;
  user: Utilisateur;
  // --------------------------------------------------

  // Capability API
  viz1 = {
    component: 'QdtViz',
    options: {
      type: 'piechart',
      id: 'RpCHU',
      width: '100%',
      height: '100%',
      exportData: false,
      exportImg: false,
      exportPdf: false,
    },
    connection: 1,
    style: {
      width: '100%',
      height: '100%',
    },
  };
  viz2 = {
    component: 'QdtViz',
    options: {
      type: 'piechart',
      id: 'FPUzeHN',
      width: '100%',
      height: '100%',
      exportData: false,
      exportImg: false,
      exportPdf: false,
    },
    connection: 1,
    style: {
      width: '100%',
      height: '100%',
    },
  };
  // ---------------------------------------------------

  constructor(
    public router: Router,
    public projetListViewService: ProjetListViewService,
    private aapService: AapService,
    private evaluateurService: EvaluateurService,
    public sharedFunction: SharedFunction,
    public utilisateurService: UserService
  ) {
    this.today = DateTimeUtils.format(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.utilisateurService.getUserObservable().subscribe(user => (this.user = user));
    this.getAaps();
  }

  onInputChange() {
    this.showQlikSenseElements = !this.showQlikSenseElements;
  }

  goToProjetParcours(): void {
    this.router.navigate(['projet-parcours']);
  }

  getAaps(): void {
    this.subscriptions.push(
      this.aapService.getAap().subscribe(response => {
        if (response) {
          this.aaps = response.body;
        }
      })
    );
  }

  /**
   * Redirige vers la page evaluateur
   */
  redirectEvaluateur(): void {
    this.subscriptions.push(this.evaluateurService.goToEvaluateurPage());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  isUserChefDeFileOrMandataire(structures: Structure[]): boolean {
    return (
      this.sharedFunction.isUserInStructureChefDeFileContacts(structures, this.user) ||
      this.sharedFunction.isUserInStructureMandataireContacts(structures, this.user)
    );
  }

  isAapActif(nomAap: string): boolean {
    return this.aaps.find(aap => aap.nom === nomAap)?.statut === this.EnumAapStatut.OUVERT;
  }

  canUserActivateProjet(projet: ProjetListViewDto): boolean {
    return this.isUserChefDeFileOrMandataire(projet.structures) && this.isAapActif(projet.nomAap);
  }

  canUserDeactivateProjet(projet: ProjetListViewDto): boolean {
    return (
      this.isUserChefDeFileOrMandataire(projet.structures) &&
      this.EnumStatutProjet.toString(EnumProjetStatut.EN_COURS) === projet.statut &&
      (this.EnumProjetEtape.toString(EnumProjetEtape.DEPOT) === projet.etape ||
        this.EnumProjetEtape.toString(EnumProjetEtape.PRE_DEPOT) === projet.etape)
    );
  }

  readonly EnumStatutProjet = EnumProjetStatut;
  readonly EnumProjetEtape = EnumProjetEtape;
  readonly EnumAapStatut = EnumAapStatut;
}
