import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepChoixRoleComponent } from './step-choix-role.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [StepChoixRoleComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedUiModule],
  exports: [StepChoixRoleComponent],
})
export class StepChoixRoleModule {}
