<article
  class="projet"
  [class]="projet.inactif ? 'projet_disabled' : ''"
  (click)="goToProjet(projet.id, projet.isEnSuivi)"
  matTooltip="{{ projet.isEnSuivi && projet.displayStatut ? projet.tooltipText : '' }}"
>
  <header id="nomAap">
    <div class="projet__aap">{{ projet.nomAap }}</div>
    <h2 class="projet__nom">{{ projet.nom }}</h2>
  </header>
  <div>
    <div class="projet__tag" [ngClass]="'projet__tag-inactif'" *ngIf="projet.inactif">Inactif</div>
  </div>

  <div id="etapesProjet" class="projet__tags">
    <div class="projet__tag" [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi, 'projet__tag-disabled': projet.inactif }">
      {{ projet.etape }}
    </div>

    <div
      class="projet__tag"
      [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi, 'projet__tag-disabled': projet.inactif }"
      *ngIf="projet.displayStatut"
    >
      {{ projet.statut }}
    </div>
    <div *ngIf="projet?.tag && projet?.tag !== 'FERME'" class="projet__tag" [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi }">
      {{ projet?.tag | toProjetTag }}
    </div>
  </div>

  <div id="dateCreationProjet" class="projet__infos">
    <div class="info" *ngIf="!projet.isEnSuivi">
      <div class="info__label">Création</div>
      <div class="info__value">{{ (projet.dateCreation | date : 'dd/MM/yyyy') || '-' }}</div>
    </div>

    <div id="releveAap" class="info">
      <div class="info__label">Prochaine {{ projet.isEnSuivi ? 'étape clé' : 'relève' }}</div>
      <div class="info__value">{{ projet.dateProcaineReleveOuEC }}</div>
    </div>
  </div>
  <div id="boutonsActivation" class="projet__activation">
    <button
      id="reactivate-projet"
      class="ds-btn ds-btn--primary activation_button"
      (click)="openProjectActivationModal(projet, true); $event.stopPropagation()"
      *ngIf="projet.inactif"
      [disabled]="!canUserActivateProjet"
      [title]="isAapActif ? '' : 'L\'AAP est fermé, vous ne pouvez pas réactiver le projet.'"
    >
      Réactiver
    </button>
    <button
      id="deactivate-projet"
      class="ds-btn ds-btn--secondary activation_button"
      (click)="openProjectActivationModal(projet, false); $event.stopPropagation()"
      *ngIf="!projet.inactif"
      [disabled]="!canUserDeactivateProjet"
    >
      Désactiver
    </button>
  </div>
</article>
