<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<div class="modal__cgu-pdf">
  <pdf-viewer id="cgu-viewer" [src]="src"></pdf-viewer>
</div>

<div class="modal__content">
  <a [href]="src" target="_blank" class="modal__cgu-link">Voir les conditions générales d’utilisation de l’extranet pixcel</a>
</div>

<div class="modal__footer">
  <div class="ds-checkbox-group modal__checkbox">
    <mat-checkbox class="" [(ngModel)]="enabledBtnCGU">
      J’ai pris connaissance des Conditions Générales d’Utilisation et les accepte sans restriction ni réserve</mat-checkbox
    >
  </div>
  <div class="ds-checkbox-group modal__checkbox">
    <mat-checkbox class="" [(ngModel)]="enabledBtnDataCollect">
      J’atteste avoir pris connaissance des mentions d’information relatives au traitement des données à caractère personnel présentées en
      <a [href]="anchorSrc" target="_blank">article IV</a> des CGU
    </mat-checkbox>
  </div>

  <div id="modal-btn" class="modal__actions">
    <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
      {{ modalData.textReturnButton }}
    </button>
    <button class="ds-btn ds-btn--primary modal__accept" [mat-dialog-close]="true" [disabled]="!enabledBtnCGU || !enabledBtnDataCollect">
      {{ modalData.textGoButton }}
    </button>
  </div>
</div>
