import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal.component';
import { MaterialModule } from '@shared-candidat/modules/material.module';

@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [CommonModule, MaterialModule],
})
export class ConfirmModalModule {}
