<h2 class="step__title">Type de projet</h2>

<div class="card--info card--red">
  <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
  Ce choix est irréversible. En cas d'erreur vous devrez créer à nouveau votre projet.
</div>

<form [formGroup]="partenaireForm" class="step__content" id="card-type">
  <p>
    Afin de vous accompagner au mieux dans la constitution de votre dossier, merci de nous indiquer si votre projet sera mené par votre
    structure seule ou par un consortium de structures.
  </p>

  <!-- Type de projet -->
  <div
    id="input-type"
    class="form-step__type ds-input-group"
    [class.ds-input-group--error]="!partenaireForm.controls['partenaireType'].valid && partenaireForm.controls['partenaireType'].touched"
  >
    <div class="ds-input-group__label">Type de projet<lib-asterisk></lib-asterisk></div>
    <div class="select-container">
      <div class="type-select">
        <input formControlName="partenaireType" type="radio" name="partenaireType" id="indiv" value="INDIV" hidden />
        <label for="indiv"> Individuel </label>
        <input formControlName="partenaireType" type="radio" name="partenaireType" id="multi" value="MULTI" hidden />
        <label for="multi"> Multi-partenaires </label>
      </div>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-type"
      *ngIf="!partenaireForm.controls['partenaireType'].valid && !partenaireForm.controls['partenaireType'].untouched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner un type de projet</div>
    </ng-container>
  </div>
</form>

<div class="step__buttons">
  <button class="ds-btn ds-btn--secondary" (click)="changingPage(false)">Précédent</button>
  <button class="ds-btn ds-btn--primary" (click)="changingPage(true)">Suivant</button>
</div>
