<lib-card-title [title]="'Choix du rôle'"></lib-card-title>

<div class="step__content form__content">
  <lib-structure-role-select [form]="form"></lib-structure-role-select>
</div>

<div class="step__buttons">
  <button class="ds-btn ds-btn--secondary" (click)="changingPage(false)">Précédent</button>
  <button class="ds-btn ds-btn--primary" (click)="changingPage(true)">Suivant</button>
</div>
