import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjetService } from '@services-candidat/projet.service';
import { ConfirmModalComponent, ProjetListViewDto, ShowToastrService } from '@shared-ui';
import { tap } from 'rxjs';

@Component({
  selector: 'pxl-projet-card',
  templateUrl: './projet-card.component.html',
  styleUrl: './projet-card.component.scss',
})
export class ProjetCardComponent {
  @Input() projet: ProjetListViewDto;
  @Input() canUserActivateProjet: boolean;
  @Input() canUserDeactivateProjet: boolean;
  @Input() isAapActif: boolean;

  constructor(
    public router: Router,
    private showToastrService: ShowToastrService,
    public matDialog: MatDialog,
    public projetService: ProjetService
  ) {}

  /*
   * Envoi sur la page des informations du projet - projet aperçu
   * */
  goToProjet(projetId: string, isProjetEnSuivi: boolean): void {
    const segment = isProjetEnSuivi ? `suivi-projet/${projetId}` : `projet-creation/${projetId}/projet-apercu`;
    this.router.navigate([segment]);
  }

  openProjectActivationModal(projet: ProjetListViewDto, activate: boolean) {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: activate ? 'Réactiver le projet' : 'Désactiver le projet',
        description: activate
          ? "Vous êtes sur le point de rendre le projet actif. Si vous changez d'avis, vous pourrez toujours le désactiver de la même manière. Confirmez-vous votre action ?"
          : "Vous êtes sur le point de rendre le projet inactif. Si vous changez d'avis, vous pourrez toujours le réactiver de la même manière. Confirmez-vous votre action ?",
        textReturnButton: 'Annuler',
        textGoButton: 'Confirmer',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projetService
          .reactivateProjet([projet.id], activate)
          .pipe(
            tap(() => {
              projet.inactif = !activate;
              this.showToastrService.success(`Le projet a bien été ${activate ? 'activé' : 'désactivé'}`);
            })
          )
          .subscribe({
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    });
  }
}
