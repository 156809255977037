<div class="page-rejoindre" *ngIf="isEval">
  <h1 class="parcours__title">Bienvenue sur Picxel</h1>

  <form class="parcours page-content" [formGroup]="evalForm" (ngSubmit)="submitFormEval(evalForm.getRawValue())">
    <div id="parcours-form" class="form">
      <!-- Nom -->
      <div
        id="input-nom"
        class="ds-input-group"
        [class.ds-input-group--error]="!evalForm.controls['nom'].valid && evalForm.controls['nom'].touched"
      >
        <label for="nom" class="ds-input-group__label">Nom *</label>
        <span class="ds-input-group__input">
          <input
            id="nom"
            name="nom"
            type="text"
            placeholder="Votre nom"
            class="ds-input-group__input"
            maxlength="30"
            pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
            formControlName="nom"
          />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container id="error-nom" *ngIf="!evalForm.controls['nom'].valid && evalForm.controls['nom'].touched">
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
        </ng-container>
      </div>

      <!-- Prénom  -->
      <div
        id="input-prenom"
        class="ds-input-group"
        [class.ds-input-group--error]="!evalForm.controls['prenom'].valid && evalForm.controls['prenom'].touched"
      >
        <label for="prenom" class="ds-input-group__label">Prénom *</label>
        <span class="ds-input-group__input">
          <input
            id="prenom"
            name="prenom"
            type="text"
            placeholder="Votre prénom"
            class="input_prenom ds-input-group__input"
            maxlength="30"
            pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
            formControlName="prenom"
          />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container id="error-prenom" *ngIf="!evalForm.controls['prenom'].valid && evalForm.controls['prenom'].touched">
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
        </ng-container>
      </div>
      <!-- tel -->
      <div
        id="input-tel"
        #inputtel
        class="ds-input-group"
        [class.ds-input-group--error]="!evalForm.controls['tel'].valid && evalForm.controls['tel'].touched"
      >
        <label id="id_input-tel-label" for="tel" class="input-tel-label ds-input-group__label">Téléphone *</label>
        <span class="ds-input-group__input">
          <input (blur)="onBlur()" id="tel" name="tel" type="tel" maxlength="20" class="ds-input-group__input" formControlName="tel" />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-tel"
          *ngIf="
            inputtel.classList.contains('ds-input-group--error') ||
            (evalForm.controls['tel'].touched && evalForm.controls['tel'].value === '')
          "
        >
          <div id="id_error-tel-msg" class="error-tel-msg ds-input-group__feedback">
            Merci de bien vouloir renseigner un numéro de téléphone valide
          </div>
        </ng-container>
      </div>

      <!-- mail -->
      <div id="input-email" class="ds-input-group">
        <label for="email" class="ds-input-group__label">Mail *</label>
        <span class="ds-input-group__input">
          <input
            id="email"
            name="email"
            type="text"
            maxlength="80"
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            placeholder="mon-email@exemple.fr"
            class="input_email ds-input-group__input"
            formControlName="email"
          />
        </span>
      </div>

      <!-- siret -->
      <div id="input-siret" *ngIf="isExpertPrive" class="ds-input-group">
        <label for="siret" class="ds-input-group__label">SIRET</label>
        <span class="ds-input-group__input">
          <input
            id="siret"
            name="siret"
            type="text"
            [maxlength]="lengthSiret"
            placeholder="SIRET de la structure"
            class="ds-input-group__input"
            formControlName="siret"
            (keypress)="onKeyPressSiret()"
            (focus)="writeSiret()"
            (focusout)="parseSiret()"
          />
        </span>
      </div>

      <!-- Raison sociale -->
      <div id="input-raison" *ngIf="isExpertPrive" class="ds-input-group">
        <label for="raison" class="ds-input-group__label">Raison Sociale</label>
        <span class="ds-input-group__input">
          <input
            id="raison"
            name="raisonSociale"
            type="text"
            maxlength="100"
            placeholder="Raison sociale de la structure"
            class="input_raison ds-input-group__input"
            formControlName="raisonSociale"
          />
        </span>
      </div>

      <!-- Ministere -->
      <div
        id="input-ministere"
        *ngIf="isRequiredMinistereRattachement"
        class="ds-input-group"
        [class.ds-input-group--error]="
          !evalForm.controls['ministereRattachement'].valid && evalForm.controls['ministereRattachement'].touched
        "
      >
        <label for="ministere" class="ds-input-group__label">Ministere de rattachement *</label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="ministereRattachement" name="ministereRattachement" formControlName="ministereRattachement">
            <option [ngValue]="null" selected disabled hidden>Merci de sélectionner votre ministère de rattachement</option>
            <option *ngFor="let item of ministeres | keyvalue" value="{{ item.key }}">{{ item.value }}</option>
          </select>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-ministere"
          *ngIf="!evalForm.controls['ministereRattachement'].valid && evalForm.controls['ministereRattachement'].touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un ministere de rattachement valide</div>
        </ng-container>
      </div>
    </div>

    <!-- FIN de Contact -->
    <div id="rgpd" class="form-rgpd">
      <ng-container *ngIf="isEval">
        Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="getRgpdFromCgu()">cliquez ici</a>
      </ng-container>
    </div>

    <div class="step__buttons">
      <button id="back-avertissement" class="ds-btn ds-btn--secondary" (click)="onGoToDashboard()">Annuler</button>
      <button id="join-project" class="ds-btn ds-btn--primary" type="submit">Accéder à Picxel</button>
    </div>
  </form>
</div>
