export enum EnumMinistere {
  'Cabinet du Premier ministre' = 'Cabinet du Premier ministre',
  "Ministere de l'Agriculture et de la Souverainete alimentaire" = "Ministère de l'Agriculture et de la Souveraineté alimentaire",
  'Ministere des Armees' = 'Ministère des Armées',
  'Ministere de la Culture' = 'Ministère de la Culture',
  "Ministere de l'Economie, des Finances et de la Souverainete industrielle et numerique" = "Ministère de l'Économie, des Finances et de la Souveraineté industrielle et numérique",
  "Ministere de l'Education nationale et de la Jeunesse" = "Ministère de l'Éducation nationale et de la Jeunesse",
  "Ministere de l'Enseignement superieur et de la Recherche" = "Ministère de l'Enseignement supérieur et de la Recherche",
  "Ministere de l'Europe et des Affaires etrangeres" = "Ministère de l'Europe et des Affaires étrangères",
  "Ministere de l'Interieur" = "Ministère de l'Intérieur",
  'Ministere de la Justice' = 'Ministère de la Justice',
  'Ministere des Outre-mer' = 'Ministère des Outre-mer',
  'Ministere de la Sante et de la Prevention' = 'Ministère de la Santé et de la Prévention',
  "Ministere des Solidarites, de l'Autonomie et des Personnes handicapees" = "Ministère des Solidarités, de l'Autonomie et des Personnes handicapées",
  'Ministere des Sports et des Jeux Olympiques et Paralympiques' = 'Ministère des Sports et des Jeux Olympiques et Paralympiques',
  'Ministere de la Transformation et de la Fonction publiques' = 'Ministère de la Transformation et de la Fonction publiques',
  'Ministere de la Transition ecologique et de la Cohesion des territoires' = 'Ministère de la Transition écologique et de la Cohésion des territoires',
  'Ministere de la Transition energetique' = 'Ministère de la Transition énergétique',
  "Ministere du Travail, du Plein emploi et de l'Insertion" = "Ministère du Travail, du Plein emploi et de l'Insertion",
}
