import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerOverlayService } from '@services-candidat/spinner-overlay.service';

import { v4 as uuidv4 } from 'uuid';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerOverlayService: SpinnerOverlayService, private oidcSecurityService: PxlOidcService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();

    let headersExtended;
    if (request.url.match(/auth/)) {
      headersExtended = request.headers;
    } else if (request.url.match(/public-bucket/) || request.url.match(/private-bucket/) || request.url.match(/upload-video/)) {
      headersExtended = request.headers.append('X-Correlation-Id', uuidv4());
    } else {
      let tokenJwt = '';
      this.oidcSecurityService.getAccessToken().subscribe(reponse => {
        tokenJwt = reponse;
      });

      headersExtended = request.headers
        .append('Content-Type', 'application/json; charset=utf-8')
        .append('X-Correlation-Id', uuidv4())
        .append('Authorization', 'Bearer ' + tokenJwt);
    }

    const cloned = request.clone({
      headers: headersExtended,
    });
    return next.handle(cloned).pipe(finalize(() => spinnerSubscription?.unsubscribe()));
  }
}
