import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { QdtViz, qdtCapabilityApp } from 'qdt-components';
@Component({
  selector: 'qdt-component',
  templateUrl: './qdt-viz.component.html',
  styleUrls: ['./qdt-viz.component.scss'],
})
export class QdtVizComponentsComponent implements OnInit {
  @Input() component: any;
  @Input() properties: any;
  @Input() options: any;
  @Input() connection: number = 1;

  mcbStr: string;
  isamStr: string;

  constructor(private elementRef: ElementRef) {}

  static capabilityApiAppPromise: Promise<any>;

  async init() {
    const token = this.getToken();
    const config = {
      host: 'staging.qls-nprd.cloud.bpifrance.fr',
      secure: true,
      port: 443,
      prefix: this.mcbStr ? 'mcb' : 'saml',
      appId: 'ef0c77d8-1a61-4f37-9831-9f2254e01b85',
    };
    QdtVizComponentsComponent.capabilityApiAppPromise = qdtCapabilityApp({ ...config, token: token });

    const { options } = this;
    const app = await QdtVizComponentsComponent.capabilityApiAppPromise;
    QdtViz({
      element: this.elementRef.nativeElement,
      app,
      options,
    });
  }

  ngOnInit() {
    this.init();
  }

  getToken(): string {
    this.mcbStr = localStorage.getItem('MCB');
    this.isamStr = localStorage.getItem('ISAM');
    if (!this.mcbStr && !this.isamStr) {
      return;
    }
    let auth: { authnResult: { id_token: string } };
    if (this.mcbStr) {
      auth = JSON.parse(this.mcbStr);
    } else {
      auth = JSON.parse(this.isamStr);
    }
    return auth?.authnResult?.id_token;
  }
}
