<h2 class="step__title">Mes informations</h2>

<!-- Informations contacts -->
<form [formGroup]="contacts">
  <div class="step__content form__content" [formGroupName]="0">
    <!-- Genre  -->
    <div
      id="input-raison"
      class="ds-input-group genre_selection_option_overwrite"
      [class.ds-input-group--error]="!contacts.controls[0].get('genre').valid && contacts.controls[0].get('genre').touched"
    >
      <label for="genre" class="ds-input-group__label">Civilité<lib-asterisk></lib-asterisk></label>
      <div class="div_genre_checkboxs">
        <label *ngFor="let genre of genres">
          <input class="btnlike" type="checkbox" (change)="selectGenre(genre)" [checked]="genre.checked" />
          <span
            [ngClass]="{ genre_invalid: contacts.controls[0].get('genre').invalid && contacts.controls[0].get('genre').touched }"
            class="unselectable"
            >{{ EnumGenre.toString(genre.valeur) }}</span
          >
        </label>
      </div>

      <!-- Icône et message d'erreur -->
      <ng-container id="error-raison" *ngIf="contacts.controls[0].get('genre').invalid && contacts.controls[0].get('genre').touched">
        <div class="ds-input-group__feedback invalid_msg_overwrite">Merci de bien vouloir renseigner une civilité valide.</div>
      </ng-container>
    </div>

    <!-- Nom -->
    <div
      id="input-nom"
      class="ds-input-group"
      [class.ds-input-group--error]="!contacts.controls[0].get('nom').valid && contacts.controls[0].get('nom').touched"
    >
      <label for="nom" class="ds-input-group__label">Nom<lib-asterisk></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          id="nom"
          name="nom"
          type="text"
          placeholder="Votre nom"
          class="ds-input-group__input"
          maxlength="30"
          pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
          formControlName="nom"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-nom" *ngIf="!contacts.controls[0].get('nom').valid && contacts.controls[0].get('nom').touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
      </ng-container>
    </div>

    <!-- Prénom  -->
    <div
      id="input-prenom"
      class="ds-input-group"
      [class.ds-input-group--error]="!contacts.controls[0].get('prenom').valid && contacts.controls[0].get('prenom').touched"
    >
      <label for="prenom" class="ds-input-group__label">Prénom<lib-asterisk></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          id="prenom"
          name="prenom"
          type="text"
          placeholder="Votre prénom"
          class="input_prenom ds-input-group__input"
          maxlength="30"
          pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
          formControlName="prenom"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-prenom" *ngIf="!contacts.controls[0].get('prenom').valid && contacts.controls[0].get('prenom').touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
      </ng-container>
    </div>
    <!-- fonction  -->
    <div
      id="input-fonction"
      class="ds-input-group"
      [class.ds-input-group--error]="!contacts.controls[0].get('fonction').valid && contacts.controls[0].get('fonction').touched"
    >
      <label for="fonction" class="ds-input-group__label">Fonction<lib-asterisk></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          id="fonction"
          name="fonction"
          type="text"
          placeholder="Votre fonction"
          class="input_fonction ds-input-group__input"
          maxlength="50"
          pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
          formControlName="fonction"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-fonction" *ngIf="!contacts.controls[0].get('fonction').valid && contacts.controls[0].get('fonction').touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une fonction valide</div>
      </ng-container>
    </div>
    <!-- telephone -->
    <div
      id="input-telephone"
      class="ds-input-group ds-input-group--telephone input-telephone"
      [class.ds-input-group--error]="!contacts.controls[0].get('telephone').valid && contacts.controls[0].get('telephone').touched"
    >
      <label for="telephone" class="ds-input-group__label">Téléphone<lib-asterisk></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          (blur)="onBlur()"
          id="telephone"
          name="telephone"
          type="tel"
          maxlength="20"
          class="ds-input-group__input"
          formControlName="telephone"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container
        id="error-telephone"
        *ngIf="!contacts.controls[0].get('telephone').valid && contacts.controls[0].get('telephone').touched"
      >
        <div class="ds-input-group__feedback input-telephone-error-msg">Merci de bien vouloir renseigner un numéro de téléphone valide</div>
      </ng-container>
    </div>

    <!-- mail -->
    <div
      id="input-email"
      class="ds-input-group"
      [class.ds-input-group--error]="!contacts.controls[0].get('email').valid && contacts.controls[0].get('email').touched"
    >
      <label for="email" class="ds-input-group__label">Mail<lib-asterisk></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          id="email"
          name="email"
          type="text"
          maxlength="80"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          placeholder="mon-email@exemple.fr"
          class="input_email ds-input-group__input"
          formControlName="email"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-email" *ngIf="!contacts.controls[0].get('email').valid && contacts.controls[0].get('email').touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
      </ng-container>
    </div>
  </div>
  <!-- FIN de Informations de la structure -->

  <div class="rgpd">
    Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="downloadRGPD()">cliquez ici</a>
    <br />
    Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
    données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au présent
    article.
  </div>

  <div class="step__buttons">
    <button id="back-consortium" type="button" class="ds-btn ds-btn--secondary" (click)="changingPage(false)">Précédent</button>
    <button id="save-projet" class="ds-btn ds-btn--primary" (click)="submit()">Créer le projet</button>
  </div>
</form>
