import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetRejoindreComponent } from './projet-rejoindre.component';

@NgModule({
  declarations: [ProjetRejoindreComponent],
  imports: [CommonModule, SharedModule],
})
export class ProjetRejoindreModule {}
