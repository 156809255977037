import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { AapService } from '@services-candidat/aap.service';
import { Aap } from '@shared-ui';
import { Subscription } from 'rxjs';
import { ParcoursCreationComponent } from './component/parcours-creation/parcours-creation.component';

@Component({
  selector: 'pxl-projet-parcours',
  templateUrl: './projet-parcours.component.html',
  styleUrls: ['./projet-parcours.component.scss'],
})
export class ProjetParcoursComponent implements OnInit, OnDestroy, AfterContentChecked {
  page = 1;
  @ViewChild(ParcoursCreationComponent) parcoursCreation;
  aap: Aap;
  aaps: Aap[] = [];
  aapSub: Subscription;

  isAapMultiMandatory = true;
  isAapIndivMandatory = false;
  isAapPersonnePhysique = false;
  structureEnCreation = false;

  constructor(private aapService: AapService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.aapSub = this.aapService.getAap().subscribe(response => {
      if (response) {
        this.aaps = response.body;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  atPage(): void {
    this.page = this.parcoursCreation.page;
  }
  ngOnDestroy(): void {
    if (this.aapSub) {
      this.aapSub.unsubscribe();
    }
  }
}
