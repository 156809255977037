import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@services-candidat/user.service';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnumPermissionUtilisateur } from '@shared-ui';

export const cloisonnementGuard: CanActivateFn = route => {
  const utilisateurService = inject(UserService);
  const router = inject(Router);
  const id = route.params.id;

  return utilisateurService.getUserObservable().pipe(
    map(user => {
      const hasSuiviPerms = user.perms.some(perm => perm.scopeId === id && perm.name === EnumPermissionUtilisateur.SUIVI_PROJET);

      if (!hasSuiviPerms) {
        router.navigate(['projet-creation', id]);
        return false;
      }
      return true;
    })
  );
};
