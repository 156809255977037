import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetRejoindrePartenaireComponent } from './projet-rejoindre-partenaire.component';

@NgModule({
  declarations: [ProjetRejoindrePartenaireComponent],
  imports: [CommonModule, SharedModule],
})
export class ProjetRejoindrePartenaireModule {}
