<h2 class="step__title">Choix de l'AAP</h2>

<div class="div_aap_container">
  <div *ngFor="let programme of programmes; let i = index">
    <h2 class="p_programme">{{ programme.nom | uppercase }}</h2>
    <div *ngFor="let aap of programme.aaps; let j = index">
      <label
        class="ds-input-group__label immatriculation-label label_radio"
        [ngClass]="{ label_radio_active: selectedAap && aap.id === selectedAap.id, label_radio_incomplet: showMessage }"
      >
        <span class="ds-input-group__input">
          <input
            class="input_radio"
            type="radio"
            [ngClass]="{ input_radio_incomplet: showMessage }"
            name="1"
            id="{{ 'Id' + i + j }}"
            (click)="selectAap(aap)"
          />
          {{ aap.nom }}
        </span>
      </label>
      <div *ngIf="aap.description && selectedAap && aap.id === selectedAap.id" class="div_aap_description">
        {{ aap.description }}
      </div>
    </div>
  </div>
  <p class="p_message" *ngIf="showMessage">Merci de bien vouloir sélectionner un type d'appel à projets.</p>
</div>

<div class="step__buttons">
  <button id="cancel-parcours" class="ds-btn ds-btn--secondary" (click)="onGoToDashboard()">
    Retour à l'accueil
  </button>
  <button class="ds-btn ds-btn--primary" (click)="changingPage(true)">
    Suivant
  </button>
</div>
