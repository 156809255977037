<div class="page-content page-content--no-scroll">
  <div class="projets__header">
    <h1 class="projets__title">Tous mes projets</h1>

    <div class="card__action-button-wrapper div_button_list_projets">
      <button id="switch-to-evaluateur" class="ds-btn ds-btn--secondary button_evaluateur_overwrite" (click)="redirectEvaluateur()">
        Accéder à mon espace evaluateur
      </button>
      <button id="add-new-projet" class="ds-btn ds-btn--primary" (click)="goToProjetParcours()" [disabled]="aaps?.length === 0">
        <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
        Nouveau projet
      </button>
      <div id="add-new-projet-tooltip" class="card__action-button-tooltip" *ngIf="aaps?.length === 0">
        <p>Aucun appel à projets ouvert actuellement.</p>
      </div>
    </div>
  </div>
  <section *ngIf="showQlikSenseElements" class="qlik-sense-container">
    <qdt-component
      [component]="viz1.component"
      [options]="viz1.options"
      [connection]="viz1.connection"
      [style]="viz1.style"
    ></qdt-component>
    <qdt-component
      [component]="viz2.component"
      [options]="viz2.options"
      [connection]="viz2.connection"
      [style]="viz2.style"
    ></qdt-component>
  </section>
  <div id="projet" class="projets__content">
    <div class="projets__list">
      @for(projet of (projetListViewService.combinedProjets$ | async); track projet.id){
      <pxl-projet-card
        [projet]="projet"
        [canUserActivateProjet]="canUserActivateProjet(projet)"
        [canUserDeactivateProjet]="canUserDeactivateProjet(projet)"
        [isAapActif]="isAapActif(projet?.nomAap)"
      ></pxl-projet-card>
      }
    </div>
  </div>
  <div class="qlik-sense-button" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.INTEGRATION_QLIKSENSE">
    <p class="ds-input-group__label">Afficher les éléments QlikSense</p>
    <div class="switch-grp">
      <label for="obligatoire" class="ds-switch ds-switch--m">
        <input type="checkbox" id="obligatoire" [value]="showQlikSenseElements" (input)="onInputChange()" />
        <span class="slider"></span>
      </label>
    </div>
  </div>
</div>
