@if (user) {
<lib-alertes-publiees [positionnement]="EnumPositionnement.CANDIDAT_EVALUATEUR" [utilisateur]="user"></lib-alertes-publiees>
}

<div id="espace-selection-holder">
  <div id="title-holder">
    <span>Bienvenue {{ user?.firstName | titlecase }}, vous trouverez vos projets ici</span>
  </div>
  <div id="espace-selection-buttons-holder">
    <div class="espace-selection-button" (click)="redirectCandidat()">
      <div class="button-label" *libNgSubscribe="projetService.combinedProjetsCount$ as projetCount">
        <span *ngIf="!projetCount">Espace candidat</span>
        <span *ngIf="projetCount > 0">{{ projetCount }} projet{{ projetCount > 1 ? 's' : '' }} dans votre espace déposant</span>
      </div>
      <div class="button-creation-project">
        <button id="access-candidat" class="ds-btn ds-btn--tertiary">
          <lib-pxl-icon class="icon btn-icon" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          Accéder
        </button>
      </div>
      <img src="assets/images/espace-candidat.svg" alt="Espace candidat" />
    </div>

    <div class="espace-selection-button" (click)="redirectEvaluateur()">
      <div class="button-label">
        <span>Mon espace évaluateur</span>
      </div>
      <div class="button-creation-project">
        <button id="access-evaluateur" class="ds-btn ds-btn--tertiary">
          <lib-pxl-icon class="icon btn-icon" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          Accéder
        </button>
      </div>
      <img src="assets/images/espace-evaluateur.svg" alt="Espace évaluateur" />
    </div>
  </div>
</div>
