import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-candidat/shared.module';
import { StepChoixAapComponent } from './step-choix-aap.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [StepChoixAapComponent],
  exports: [StepChoixAapComponent],
  providers: [],
})
export class StepChoixAapModule {}
