import { Component, inject } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { EnumAuthConfigurationType, EnumPositionnement } from '@shared-ui';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss'],
})
export class AccueilComponent {
  public oidcSecurityService = inject(PxlOidcService);

  connexion(): void {
    const idp: EnumAuthConfigurationType = environment.mcb.enabled ? EnumAuthConfigurationType.MCB : EnumAuthConfigurationType.ISAM;
    localStorage.setItem('redirect', '/espace');
    localStorage.setItem('idp', idp);
    this.oidcSecurityService.authorize(idp);
  }

  inscription(): void {
    const url = environment.mcb.enabled
      ? environment.mcb.createAccountUrl +
        '?client_id=' +
        environment.mcb.clientId +
        '&redirect_uri=' +
        `${window.location.origin}/espace` +
        '&scope=' +
        environment.mcb.scopes +
        '&response_type=' +
        environment.response_type +
        '&acr_values=register'
      : environment.isam.stsServer + '/mga/sps/authsvc?PolicyId=urn:ibm:security:authentication:asf:bpiself-createacc';

    window.open(url, '_blank');
  }

  protected readonly EnumPositionnement = EnumPositionnement;
}
