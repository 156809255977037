import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterneComponent } from './interne.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [InterneComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class InterneModule { }
