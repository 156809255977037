import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EnumTypeStructure, ProgrammeAap, Aap } from '@shared-ui';

@Component({
  selector: 'step-choix-aap',
  templateUrl: './step-choix-aap.component.html',
  styleUrls: ['./step-choix-aap.component.scss'],
})
export class StepChoixAapComponent implements OnChanges {
  lengthSiret = 17;

  structureType = EnumTypeStructure;

  programmes: ProgrammeAap[] = [];

  selectedAap: Aap = null;

  showMessage = false;

  @Input() aaps: Aap[] = [];
  @Output() changePage = new EventEmitter<any>();
  @Output() aap = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.sortAapsByProgrammes();
  }

  changingPage(switchPage: boolean): void {
    if (this.selectedAap) {
      this.aap.emit(this.selectedAap);
      this.changePage.emit(switchPage);
    } else {
      this.showMessage = true;
    }
  }

  onGoToDashboard(): void {
    this.router.navigate(['/projets']);
  }

  sortAapsByProgrammes(): void {
    this.addProgrammesNoms();
    this.aaps.forEach(aap => {
      this.programmes.forEach(prog => {
        if (prog.nom === aap.programme.nom) {
          prog.aaps.push(aap);
        }
      });
    });
  }

  addProgrammesNoms(): void {
    this.aaps.forEach(aap => {
      if (!this.programmes.some(programme => programme.nom === aap.programme.nom)) {
        const programme = new ProgrammeAap();
        programme.nom = aap.programme.nom;
        this.programmes.push(programme);
      }
    });
  }

  selectAap(aap: Aap): void {
    this.selectedAap = aap;
    this.showMessage = false;
    this.aap.emit(aap);
  }
}
