import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pxl-interne',
  templateUrl: './interne.component.html',
  styleUrls: ['./interne.component.scss'],
})
export class InterneComponent {
  constructor(private router: Router) {}

  goToEspace(): void {
    this.router.navigate(['/espace']);
  }
}
