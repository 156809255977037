import { enumKeys } from '../../utils/enum-utils';

export enum EnumRolePartenaire {
  INSTRUCTEUR_EXTERNE = 'INSTRUCTEUR_EXTERNE',
  INSTRUCTEUR_VALIDEUR = 'INSTRUCTEUR_VALIDEUR',
}

export namespace EnumRolePartenaire {
  export function toString(role: EnumRolePartenaire): string {
    switch (role) {
      case EnumRolePartenaire.INSTRUCTEUR_EXTERNE:
        return 'Membre organisme';
      case EnumRolePartenaire.INSTRUCTEUR_VALIDEUR:
        return 'Valideur de comptes';
      default:
        return 'erreur de traduction';
    }
  }

  export function all(): EnumRolePartenaire[] {
    return enumKeys(EnumRolePartenaire) as unknown as EnumRolePartenaire[];
  }
}
