import { Pipe, PipeTransform } from '@angular/core';
import { EnumProjetTag } from '@shared-ui';

@Pipe({
  name: 'toProjetTag',
})
export class ToProjetTagPipe implements PipeTransform {
  transform(value: string): string {
    return value && value !== EnumProjetTag[EnumProjetTag.FERME] ? (EnumProjetTag as any)[value] : '';
  }
}
