<div class="page-rejoindre" *ngIf="canCreateEval">
  <h1 class="parcours__title">Bienvenue sur l'espace évaluateur PICXEL</h1>

  <form
    *ngIf="!formSubmitted"
    class="parcours page-content"
    [formGroup]="partenaireForm"
    (ngSubmit)="submitFormPartenaire(partenaireForm.getRawValue())"
  >
    <h2 class="step__title">Mes informations</h2>
    <p>Vous vous inscrivez en tant que membre de : {{ getOrganismeName() }}</p>
    <div id="parcours-form" class="form">
      <!-- Nom -->
      <div
        id="input-instructeurNom"
        class="ds-input-group"
        [class.ds-input-group--error]="
          !partenaireForm.controls['instructeurNom'].valid && partenaireForm.controls['instructeurNom'].touched
        "
      >
        <label for="instructeurNom" class="ds-input-group__label">Nom *</label>
        <span class="ds-input-group__input">
          <input
            id="instructeurNom"
            name="instructeurNom"
            type="text"
            placeholder="Votre nom"
            class="ds-input-group__input"
            maxlength="30"
            pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
            formControlName="instructeurNom"
          />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-nom"
          *ngIf="!partenaireForm.controls['instructeurNom'].valid && partenaireForm.controls['instructeurNom'].touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
        </ng-container>
      </div>

      <!-- Prénom  -->
      <div
        id="input-instructeurPrenom"
        class="ds-input-group"
        [class.ds-input-group--error]="
          !partenaireForm.controls['instructeurPrenom'].valid && partenaireForm.controls['instructeurPrenom'].touched
        "
      >
        <label for="instructeurPrenom" class="ds-input-group__label">Prénom *</label>
        <span class="ds-input-group__input">
          <input
            id="instructeurPrenom"
            name="instructeurPrenom"
            type="text"
            placeholder="Votre prénom"
            class="input_instructeurPrenom ds-input-group__input"
            maxlength="30"
            pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
            formControlName="instructeurPrenom"
          />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-prenom"
          *ngIf="!partenaireForm.controls['instructeurPrenom'].valid && partenaireForm.controls['instructeurPrenom'].touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
        </ng-container>
      </div>
      <!-- tel -->
      <div
        id="input-instructeurTelephone"
        #inputtel
        class="ds-input-group ds-input-group--telephone input-telephone"
        [class.ds-input-group--error]="
          !partenaireForm.controls['instructeurTelephone'].valid && partenaireForm.controls['instructeurTelephone'].touched
        "
      >
        <label id="id_input-tel-label" for="instructeurTelephone" class="input-tel-label ds-input-group__label">Téléphone *</label>
        <span class="ds-input-group__input">
          <input
            (blur)="onBlur()"
            id="instructeurTelephone"
            name="instructeurTelephone"
            type="instructeurTelephone"
            maxlength="20"
            class="ds-input-group__input"
            formControlName="instructeurTelephone"
          />
        </span>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-instructeurTelephone"
          *ngIf="
            inputtel.classList.contains('ds-input-group--error') ||
            (partenaireForm.controls['instructeurTelephone'].touched && partenaireForm.controls['instructeurTelephone'].value === '')
          "
        >
          <div id="id_error-tel-msg" class="error-tel-msg ds-input-group__feedback">
            Merci de bien vouloir renseigner un numéro de téléphone valide
          </div>
        </ng-container>
      </div>

      <!-- mail -->
      <div id="input-email" class="ds-input-group">
        <label for="email" class="ds-input-group__label">Mail *</label>
        <span class="ds-input-group__input">
          <input
            id="email"
            name="email"
            type="text"
            maxlength="80"
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            placeholder="mon-email@exemple.fr"
            class="input_email ds-input-group__input"
            formControlName="email"
          />
        </span>
      </div>

      <!-- Raison sociale -->
      <div
        id="input-raison"
        class="ds-input-group"
        [class.ds-input-group--error]="
          listOrganisme.length !== 1 && !partenaireForm.controls['organismeId'].valid && partenaireForm.controls['organismeId'].touched
        "
      >
        <label for="raison" class="ds-input-group__label">Nom de l'organisme*</label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="raison" name="organismeId" formControlName="organismeId">
            <option [ngValue]="null" selected disabled>Merci de sélectionner le nom de votre organisme</option>
            <option *ngFor="let item of listOrganisme" value="{{ item.id }}">{{ item.nom }}</option>
          </select>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-raison"
          *ngIf="
            listOrganisme.length !== 1 && !partenaireForm.controls['organismeId'].valid && partenaireForm.controls['organismeId'].touched
          "
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
        </ng-container>
      </div>

      <!-- siret -->
      <div id="input-siret" class="ds-input-group">
        <label for="siret" class="ds-input-group__label">SIRET *</label>
        <span class="ds-input-group__input">
          <input
            id="siret"
            name="siret"
            type="text"
            placeholder="SIRET de la structure"
            class="ds-input-group__input"
            formControlName="siret"
          />
        </span>
      </div>

      <!-- Role évaluateur demandé -->
      <div
        id="input-instructeurRole"
        class="ds-input-group"
        [class.ds-input-group--error]="
          !partenaireForm.controls['instructeurRole'].valid && partenaireForm.controls['instructeurRole'].touched
        "
      >
        <div class="ds-input-group__label">Rôle *</div>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="instructeurRole" name="instructeurRole" formControlName="instructeurRole">
            <option [ngValue]="null" selected disabled>Précisez votre rôle</option>
            <option *ngFor="let item of EnumRolePartenaire.all()" [value]="item">{{ EnumRolePartenaire.toString(item) }}</option>
          </select>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-instructeurRole"
          *ngIf="!partenaireForm.controls['instructeurRole'].valid && partenaireForm.controls['instructeurRole'].touched"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre rôle</div>
        </ng-container>
      </div>
    </div>

    <!-- FIN de Contact -->
    <div id="rgpd" class="form-rgpd">
      <ng-container>
        Cliquez pour en savoir plus sur <a (click)="getRgpdFromCgu()">la gestion de vos données personnelles.</a>
      </ng-container>
      <br />
      <ng-container>
        Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
        données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au
        présent article.
      </ng-container>
    </div>

    <div class="step__buttons">
      <button id="back-avertissement" class="ds-btn ds-btn--secondary" (click)="onGoToDashboard()">Annuler</button>
      <button id="join-project" class="ds-btn ds-btn--primary" type="submit">Accéder à Picxel</button>
    </div>
  </form>

  <div class="page-content submit-success" *ngIf="formSubmitted">
    <img src="assets/images/inscription-envoyee.svg" alt="Inscription envoyée" />

    <h4>Votre inscription est presque terminée !</h4>

    <p>
      Un administrateur va passer en revue votre profil pour vous donner pleinement accès à la plateforme PICXEL. Vous serez notifié par
      mail une fois votre profil validé.
    </p>
  </div>
</div>
