import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'step-choix-role',
  templateUrl: './step-choix-role.component.html',
  styleUrls: ['./step-choix-role.component.scss'],
})
export class StepChoixRoleComponent {
  @Input() form: FormGroup;
  @Output() changePage = new EventEmitter<any>();

  changingPage(switchPage: boolean): void {
    this.changePage.emit(switchPage);
  }
}
