import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '@environments-candidat/environment';
import { MyStorageService } from './storage.service';
import { EnumAuthConfigurationType } from '@shared-ui';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: [
        {
          configId: EnumAuthConfigurationType.MCB,
          authority: environment.mcb.stsServer,
          authWellknownEndpointUrl: environment.mcb.authWellknownEndpoint,
          redirectUrl: `${window.location.origin}/espace`,
          clientId: environment.mcb.clientId,
          scope: environment.mcb.scopes,
          responseType: 'code',
          triggerAuthorizationResultEvent: true,
          startCheckSession: false,
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true,
          postLoginRoute: '/projets',
          forbiddenRoute: '/',
          unauthorizedRoute: '/',
          autoUserInfo: false,
          logLevel: LogLevel.None,
          historyCleanupOff: true,
          postLogoutRedirectUri: window.location.origin,
        },
        {
          configId: EnumAuthConfigurationType.ISAM,
          authority: environment.isam.stsServer,
          authWellknownEndpointUrl: environment.isam.authWellknownEndpoint,
          redirectUrl: `${window.location.origin}`,
          clientId: environment.isam.clientId,
          scope: environment.isam.scopes,
          responseType: 'code',
          triggerAuthorizationResultEvent: true,
          postLogoutRedirectUri: window.location.origin,
          startCheckSession: false,
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true,
          postLoginRoute: '/projets',
          forbiddenRoute: '/',
          unauthorizedRoute: '/',
          autoUserInfo: false,
          logLevel: LogLevel.None,
          historyCleanupOff: true,
        },
      ],
    }),
  ],
  providers: [{ provide: AbstractSecurityStorage, useClass: MyStorageService }],
  exports: [AuthModule],
})
export class AuthConfigModule {}
