import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, GuardsCheckEnd, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, filter, of, take } from 'rxjs';

import { FeatureFlagService } from '@shared-ui';

@Injectable({ providedIn: 'root' })
export class FeatureFlippingGuard {
  constructor(private router: Router, private featureFlagService: FeatureFlagService) {}

  /**
   * Feature flipping guard to check if a feature is accessible or not
   * @param route Activated route
   * @returns Boolean : true if the feature is activated and false if not
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.router.events
      ?.pipe(
        filter((e: any): e is RouterEvent => e instanceof RouterEvent),
        take(1)
      )
      .subscribe((evt: RouterEvent) => {
        if (evt instanceof NavigationEnd || evt instanceof GuardsCheckEnd) {
          const selectedFeatures = route.data.featureName;
          if (Array.isArray(selectedFeatures)) {
            selectedFeatures.forEach(feature => {
              this.checkFeatureAndRedirect(feature);
            });
          } else {
            this.checkFeatureAndRedirect(selectedFeatures);
          }
        }
      });
    return of(true);
  }

  checkFeatureAndRedirect(featureName: string) {
    if (this.featureFlagService.featureOff(featureName)) {
      this.router.navigate(['/']);
      return of(false);
    }
  }
}
