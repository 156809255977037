import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperProjetComponent } from './stepper.projet.component';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [StepperProjetComponent],
  exports: [StepperProjetComponent],
  providers: [],
})
export class StepperProjetModule {}
