import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services-candidat/user.service';
import { FeatureFlagService } from '@shared-ui';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InterneGuard {
  constructor(private router: Router, private featureFlagService: FeatureFlagService, private userService: UserService) {}

  /**
   * Guard to check if the page is accessible or not to admins
   */
  canActivate(): Observable<boolean> {
    if (!this.featureFlagService.featureOff('blockInterne')) {
      this.router.navigate(['/interne']);
      return of(!this.userService.isUserInterne());
    }

    return of(true);
  }
}
