import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { Aap, GenreItem, EnumGenre } from '@shared-ui';

@Component({
  selector: 'step-contact',
  templateUrl: './step-contact.component.html',
  styleUrls: ['./step-contact.component.scss'],
})
export class StepContactComponent {
  @Input() contacts: UntypedFormArray;
  @Input() aap: Aap;
  @Output() changePage = new EventEmitter<any>();
  @Output() submitForm = new EventEmitter<any>();

  genres: Array<GenreItem> = EnumGenre.all().map(item => ({ valeur: item, checked: false }));

  changingPage(switchPage: boolean): void {
    this.changePage.emit(switchPage);
  }

  submit(): void {
    this.submitForm.emit();
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-telephone');
    if (inputPhone.classList.contains('ds-input-group--error')) {
      this.contacts['controls']['0']['controls'].telephone.setErrors({ incorrect: true });
    }
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  selectGenre(genre: GenreItem): void {
    const itemIndex = this.genres.findIndex(item => item.valeur === genre.valeur);
    if (!genre.checked) {
      this.genres.forEach(genreItem => {
        genreItem.checked = false;
      });
      this.genres[itemIndex].checked = true;
      this.contacts.controls[0].get('genre').setValue(genre.valeur);
    } else {
      this.genres[itemIndex].checked = false;
      this.contacts.controls[0].get('genre').setValue(null);
    }
  }

  protected readonly EnumGenre = EnumGenre;
}
