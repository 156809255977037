import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { ProjetService } from '@services-candidat/projet.service';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { UserService } from '@services-candidat/user.service';
import { URL_CGU } from '@shared-candidat/utils/constants';
import {
  EnumMinistere,
  Evaluateur,
  Invitation,
  PermissionUtils,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { NgxPermissionsService } from 'ngx-permissions';

declare const dsLib: any;

@Component({
  selector: 'pxl-projet-rejoindre',
  templateUrl: './projet-rejoindre.component.html',
  styleUrls: ['./projet-rejoindre.component.scss'],
})
export class ProjetRejoindreComponent extends SubscriptionDestroyerComponent implements OnInit {
  private EXPERT_PRIVE_ROLES: string[] = [
    'EXPERT_PRIVE',
    'EXPERT_INDEPENDANT',
    'EXPERT_JURY_NATIONAL',
    'VICE_PRESIDENT_JURY_NATIONAL',
    'EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE',
  ];
  private ETAT_ROLES = ['EXPERT_MINISTERIEL', 'EVALUATEUR_ETAT_EN_LECTURE_SEULE'];

  private invitationId: string;
  evalForm: UntypedFormGroup;
  isEval = false;
  lengthSiret = 17;

  isExpertPrive = false;
  isRequiredMinistereRattachement = false;

  evaluateur: Evaluateur;
  ministeres = EnumMinistere;
  tel = {};

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public projetService: ProjetService,
    private userService: UserService,
    private evalService: EvaluateurService,
    private oidcSecurityService: PxlOidcService,
    private permissionsService: NgxPermissionsService,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.queryParamMap.get('invitation_id');

    if (this.route.snapshot.url[0].path === 'rejoindre-projet') {
      this.rejoindreProjetCandidat();
    } else if (this.route.snapshot.url[0].path === 'rejoindre-projet-evaluateur') {
      this.rejoindreProjetEvaluateur();
    }
  }

  createFormExpertPrive(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.tel = new dsLib.InputPhone('tel');

    this.isEval = true;
    this.isExpertPrive = true;

    this.oidcSecurityService
      .getUserData()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: userData => {
          if (userData) {
            this.evalForm = this.formBuilder.group({
              nom: [userData.lastName, Validators.required],
              prenom: [userData.firstName, Validators.required],
              tel: [null, Validators.required],
              email: [userData.emailAddress],
              siret: [null],
              raisonSociale: [null],
            });

            this.evalForm.controls['email'].disable();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  createInternalEvaluateurForm(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.tel = new dsLib.InputPhone('tel');

    this.isEval = true;
    this.isRequiredMinistereRattachement = true;

    this.oidcSecurityService
      .getUserData()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: userData => {
          if (userData) {
            this.evalForm = this.formBuilder.group({
              nom: [userData.lastName, Validators.required],
              prenom: [userData.firstName, Validators.required],
              tel: [null, Validators.required],
              email: [userData.emailAddress],
              ministereRattachement: [null, Validators.required],
            });

            this.evalForm.controls['email'].disable();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  createFormPoleLabellisateur(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.tel = new dsLib.InputPhone('tel');

    this.isEval = true;

    this.oidcSecurityService
      .getUserData()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: userData => {
          if (userData) {
            this.evalForm = this.formBuilder.group({
              nom: [userData.lastName, Validators.required],
              prenom: [userData.firstName, Validators.required],
              tel: [null, Validators.required],
              email: [userData.emailAddress],
            });

            this.evalForm.controls['email'].disable();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  rejoindreProjetCandidat(): void {
    this.projetService
      .rejoindreProjet(this.invitationId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          const invitation: Invitation = response.body;
          this.showToastrService.success('Vous avez rejoint le projet avec succès.');

          this.userService
            .getUtilisateur()
            .pipe(this.takeUntilDestroyed())
            .subscribe((utilisateur: Utilisateur) => {
              if (utilisateur) {
                this.permissionsService.flushPermissions();
                this.permissionsService.addPermission(PermissionUtils.getProjetPermissions(utilisateur, invitation.idProjet));

                if (utilisateur.cgus[utilisateur.cgus.length - 1].dateValidation) {
                  this.goToProjetList();
                }
              }
            });
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
          this.userService
            .getUserObservable()
            .pipe(this.takeUntilDestroyed())
            .subscribe((utilisateur: Utilisateur) => {
              if (utilisateur && utilisateur.cgus[utilisateur.cgus.length - 1].dateValidation) {
                this.goToProjetList();
              }
            });
        },
      });
  }

  rejoindreProjetEvaluateur(): void {
    this.evalService
      .validateInvitation(this.invitationId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          this.evaluateur = response.body;

          if (this.evaluateur.tel) {
            this.consumeInvitation();
          } else {
            const projetAffectation = this.evaluateur.listAffectationProjets ? this.evaluateur.listAffectationProjets[0] : null;

            if (this.EXPERT_PRIVE_ROLES.includes(projetAffectation?.utilisateurRole)) {
              this.createFormExpertPrive();
            } else if (
              this.ETAT_ROLES.includes(projetAffectation?.utilisateurRole) ||
              this.evaluateur.aaps != null ||
              this.evaluateur.programmes?.utilisateurRole
            ) {
              this.createInternalEvaluateurForm();
            } else if (projetAffectation?.utilisateurRole === 'POLE_LABELLISATEUR') {
              this.createFormPoleLabellisateur();
            }
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
          this.router.navigate(['/espace']);
        },
      });
  }

  submitFormEval(formEval: any): void {
    if (this.evalForm.touched && this.evalForm.valid) {
      if (formEval.siret) {
        formEval.siret = formEval.siret.replace(/\s/g, '');
      }
      this.consumeInvitation(formEval);
    } else {
      this.evalForm.markAllAsTouched();
    }
  }

  consumeInvitation(formEval?: any): void {
    if (formEval) {
      Object.assign(this.evaluateur, formEval);
    }

    this.userService
      .updateEvaluateur(this.invitationId, this.evaluateur)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success("Vous avez rejoint l'espace évaluateur avec succès.");
          this.evalService.redirectToEvalPage();
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-tel');
    if (inputPhone.classList.contains('ds-input-group--error')) {
      this.evalForm.controls.tel.setErrors({ incorrect: true });
    }
  }

  getRgpdFromCgu(): void {
    window.open(URL_CGU + '#rgpd');
  }

  /*
   * Reformat le siret pour ecriture
   * */
  writeSiret(): void {
    this.lengthSiret = 14;
    this.evalForm.controls.siret.setValidators([Validators.minLength(14), Validators.pattern('[a-zA-Z0-9\\s]+')]);
    if (this.evalForm.value.siret) {
      this.evalForm.patchValue({
        siret: this.evalForm.value.siret.replace(/\s/g, ''),
      });
    }
  }

  onKeyPressSiret(): void {
    this.lengthSiret = 14;
  }

  /*
   * Reformat le Siret affiché
   * */
  parseSiret(): void {
    if (this.evalForm.value.siret) {
      this.evalForm.patchValue({
        siret: this.evalForm.value.siret.replace(/\s/g, ''),
      });
      if (this.evalForm.value.siret.length === 14) {
        this.lengthSiret = 17;
        this.evalForm.patchValue({
          siret: this.evalForm.value.siret.replace(/.{13}(?=.)/, (m: string) =>
            m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))
          ),
        });
      }
    }
  }

  onGoToDashboard(): void {
    this.router.navigate(['/espace']);
  }

  goToProjetList(): void {
    this.router.navigate(['projets']);
  }
}
